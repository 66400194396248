export default {
    "library": {
        "add_element_button": "Добавить КПЭ в группу",
        "element_edit_form_name": "Наименование КПЭ",
        "element_edit_form_desc": "Описание КПЭ"
    },
    "cards": {
        "add_cards_button": "Добавить новую карту",
        "card_restored_message": "Карта КПЭ успешно восстановлена"
    },
    "card": {
        "section_add_element_button": "Добавить КПЭ",
        "section_element_name_column_placeholder": "Внесите название КПЭ",
        "section_element_desc_column_placeholder": "Вы можете добавить описание цели, если необходимо"
    }
}