const Constants = {
    comment: {
        entityTypes: {
            TYPE_CARD: 'card',
            TYPE_CARD_ELEMENT: 'card_element',
        }
    },

    user: {
        subordinates:{
            SUBORDINATES_NONE: null,
            SUBORDINATES_DIRECT: 1,
            SUBORDINATES_ALL: 2,
        }
    },

    stage:{
        types: {
            TYPE_PLAN: 'plan',
            TYPE_CORRECTION: 'correction',
            TYPE_INTERMEDIATE_ASSESSMENT: 'intermediate_assessment',
            TYPE_ASSESSMENT: 'assessment',
        }
    },

    card: {
        statuses: {
            STATUS_COMPLETED: 200,
        }
    }

}

export default {
    install(Vue) {
        Vue.prototype.$constants = Constants;
    }
}