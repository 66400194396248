<template>
  <el-dialog
    title="Редактирование цели"
    :visible.sync="show"
    width="40%"
  >
    <el-form
      size="small"
      ref="editForm"
      label-position="top"
      :model="element"
      :rules="elementRules"
    >
      <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_name')" prop="name">
        <el-input maxlength="255" show-word-limit v-model="element.name"></el-input>
      </el-form-item>
      <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_desc')" prop="desc">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" v-model="element.desc"></el-input>
      </el-form-item>

      <el-form-item
        v-if="Object.keys($companyConfiguration.scales.getScales()).length > 1"
        label="Шкала"
        prop="scale_type"
      >
        <el-select
          v-model="element.scale_type"
          placeholder="Выберите шкалу"
        >
          <el-option
            v-for="(scale, scaleType) in $companyConfiguration.scales.getScales()"
            :key="scaleType"
            :label="scale.showName"
            :value="scaleType"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <component
      ref="elementScale"
      :is="scaleEditTemplate"
      :data="element.scale"
    ></component>

    <div slot="footer" class="dialog-footer">
      <el-popconfirm
        v-if="$canAndRulesPass(element, $permissions.LIBRARY.ELEMENT_DELETE)"
        style="float:left"
        v-show="element.id"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить KPI?"
        @confirm="preDeleteElement"
      >
        <el-button
          slot="reference"
          type="danger"
          size="small"
          plain
          icon="el-icon-delete"
          :loading="loading"
        >
          Удалить
        </el-button>
      </el-popconfirm>
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        type="primary"
        size="small"
        :loading="loading"
        @click="saveElement"
      > Сохранить
      </el-button>
    </div>

  </el-dialog>
</template>

<script>


export default {
  name: "library-element-edit-dialog",
  components: {},

  props: {
    currentFolder: {},
  },

  computed: {
    scaleEditTemplate() {
      if( !this.element.scale_type ){
        return null;
      }
      return this.$companyConfiguration.scales.getScaleTemplate(this.element.scale_type, 'libraryEditForm');
    },
  },
  data() {
    return {
      show: false,
      loading: false,

      element: {
        id: null,
        folder_id: null,
        name: null,
        desc: null,
        scale_type: null,
        scale_id: null,
        related_card_elements_count: 0,
        scale: {},
      },

      elementRules: {
        name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        scale_type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },

    }
  },
  mounted() {

  },
  methods: {
    open(element,isClone=false) {
      if( this.$refs.editForm ){
        this.$refs.editForm.resetFields();
      }

      this.element = Object.assign({}, element);

      if( isClone ){
        this.element.id = null;
        this.element.scale_id = null;
      }

      // autofill scale_type if only one in system
      if( Object.keys(this.$companyConfiguration.scales.getScales()).length === 1 ){
        this.element.scale_type = Object.keys(this.$companyConfiguration.scales.getScales())[0];
      }

      this.show = true;
    },
    close() {
      this.show = false;
    },
    preDeleteElement() {
      if( !this.element.related_card_elements_count ){
        this.deleteElement();
        return;
      }
      this.$confirm('Данный показатель используется в '+this.element.related_card_elements_count+' элементах карт. Вы действительно хотите удалить его? Будут удалены все элементы в картах', 'Внимание', {
        confirmButtonText: 'Да, удалить',
        cancelButtonText: 'нет',
        type: 'warning'
      }).then(() => {
        this.deleteElement();
      });
    },
    deleteElement(){
      this.loading = true;
      this.$api.library.deleteElement({
        id: this.element.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Удалено',
            message: 'Элемент успешно удален'
          });
        })
        .finally(()=>{
          this.loading = false;
          this.close();
          this.$emit('element-saved');
        })
    },

    saveElement() {
      this.$refs.editForm.validate().then(()=>{
        this.$refs.elementScale.$refs.editForm.validate().then(()=>{
          this.loading = true;
          this.$api.library.saveElement({
            id: this.element.id,
            folder_id: this.element.folder_id ? this.element.folder_id : this.currentFolder.id,
            name: this.element.name,
            desc: this.element.desc,
            unit: this.element.unit,
            scale_type: this.element.scale_type,
            scale_id: this.element.scale_id,
            scale_data: this.$refs.elementScale.scale,
          })
            .then(data => {
              this.$notify.success({
                title: 'Сохранено',
                message: 'Элемент успешно сохранен'
              });
            })
            .finally(()=>{
              this.loading = false;
              this.close();
              this.$emit('element-saved');
            })
        })
      })
    },
  }
}
</script>

<style>

</style>