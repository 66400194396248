<template>
  <div>
    <page-heading
      heading="Матрица КПЭ"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <div>
      <select-cards-modal
        @cards-selected="cardsSelected"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          icon="fas fa-list"
        >
          Выбрать карты
        </el-button>

      </select-cards-modal>
    </div>


    <div style="margin-top: 20px;" v-loading="loading">
      <matrix-section
        v-for="section in matrix.sections"
        :key="section.type"
        :section="section"
        :headers="matrix.headers"
        @remove-card="removeCard"
      ></matrix-section>
    </div>


  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import MatrixSection from "@/components/matrix/MatrixSection";
import SelectCardsModal from "@/components/matrix/SelectCardsModal";

export default {
  name: 'Matrix',
  components: {SelectCardsModal, MatrixSection, PageHeading},

  computed: {},
  data() {
    return {
      loading: false,
      selectedCards: [],

      matrix: {
        sections: {},
        headers: {},
      },
    }
  },
  mounted() {
    // this.loadMatrix();
  },
  methods: {

    cardsSelected(cards){
      this.selectedCards = [...this.selectedCards, ...cards];
      this.loadMatrix();
    },

    removeCard(cardId){
      this.selectedCards = this.selectedCards.filter(c => c.id !== cardId);
      this.loadMatrix();
    },

    loadMatrix() {
      this.loading = true;
      let cardIds = this.selectedCards.map( card => card.id );
      this.$api.matrix.view({
        // card_ids: [7,8],
        card_ids: cardIds,
        expand: [
          'scale',
          'permissions',
          'fields_permissions',
          'last_comment_by_stage',
          'comments_count_by_stage',
          (this.$companyConfiguration.card.getElementMediumFactsConfig()['available'] ? 'medium_facts' : null),
        ].join(),
      })
        .then((data) => {
          this.matrix = data.matrix;
        })
        .finally(() => {
          this.loading = false;
        })
    },




  }
}

</script>


<style scoped lang="scss">
@import "./src/assets/css/project-variables";


</style>
