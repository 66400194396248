import Vue from "vue";
import Vuex from "vuex";

import Api from '../api/base'
import ConstantsPlugin from '../plugins/constants'
import router from '@/router'

import cardModule from './modules/card'
import settingsModule from './modules/settings'

Vue.use(Vuex);
Vue.use(ConstantsPlugin);



export default new Vuex.Store({
    state: {
        user: {
            id: null,
            permissions: [],
            chief: {}
        },
    },
    getters: {
        user(state) {
            return state.user
        },

    },
    actions: {
        fetchUser(ctx) {
            Api.user.getAuthUser({
                expand: 'permissions'
            })
                .then(data => {
                    ctx.commit('updateUser', data.user);
                })
        },


    },


    mutations: {
        updateUser(state, user) {
            state.user = user;
        },
    },

    modules: {
        card: cardModule,
        settings: settingsModule,
    }
})