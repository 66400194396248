<template>
  <div>
    <page-heading
      heading="Настройка ролей"
    >
      <span slot="help"></span>
      <span slot="right"></span>
    </page-heading>

    <el-card
      v-if="$canAndRulesPass(user, $permissions.RBAC.LIST)"
      style="margin-top: 20px;"
    >
      <rbac-table></rbac-table>
    </el-card>




  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import RbacTable from "@/components/settings/rbac/RbacTable";
import {mapGetters} from "vuex";
import ScalesSettings from "@/componentsCompany/tmk/scalesSettings/ScalesSettings.vue";
import ChiefsEditComponent from "@/components/user/ChiefsEditComponent.vue";

export default {
  name: 'Rbac',
  components: { RbacTable, PageHeading},

  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style lang="scss">
@import "./src/assets/css/project-variables";


</style>
