<template>
  <div>
    <home-navigation></home-navigation>

    <el-divider></el-divider>

    <home-charts
      v-if="$canAndRulesPass(user, $permissions.USER.SEE_HOME_CHARTS)"
    ></home-charts>

  </div>
</template>

<script>

import HomeNavigation from "@/components/home/HomeNavigation";
import HomeCharts from "@/components/home/HomeCharts.vue";
import {mapGetters} from "vuex";

export default {
  name: 'Home',
  components: {HomeCharts, HomeNavigation},

  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
  },
  methods: {}
}

</script>

<style lang="scss">

</style>